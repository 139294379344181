import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine
Alpine.plugin(collapse)

Alpine.start()

function callAjaxAttribute(idProductAttribute){
    $.ajax({
        url: '/index.php?fc=module&module=guiltek_module&controller=ReferenceCommerciale',
        method: 'POST',
        data: { id_product_attribute: idProductAttribute },
        success: function(response) {
            response = JSON.parse(response)
            console.log(response.reference)
            let ref = document.querySelector('#js-reference')
            if(ref){
                ref.textContent = response.reference;
            }
        }
    });
}

prestashop.on('updatedProduct', function(event) {
    let idProductAttribute = event.id_product_attribute;
    callAjaxAttribute(idProductAttribute)
});
